import {ConfigurationState} from '@api/posMiddlewareCore/constants/configurationState';
import {DocumentType} from '@api/posMiddlewareCore/constants/documentType';
import {ImportState} from '@api/posMiddlewareCore/constants/importState';
import {ImportType} from '@api/posMiddlewareCore/constants/importType';
import {PromotionState} from '@api/posMiddlewareCore/constants/promotionState';
import {VoucherEmissionType} from '@api/posMiddlewareCore/constants/voucherEmissionType';
import {VoucherState} from '@api/posMiddlewareCore/constants/voucherState';
import {useEnumStore} from '@middleware-ui/modules/AppCore/store/EnumStore';
import {
  map,
  values,
} from 'lodash-es';
import {computed} from 'vue';
import {useI18n} from 'vue-i18n';

export function useStaticEnums() {
  const i18n = useI18n();
  const enumStore = useEnumStore();

  const cashierValidityOptions = computed(() => {
    return [
      {
        id: true,
        label: i18n.t('cashier.fields.isValidEnum.true'),
      },
      {
        id: false,
        label: i18n.t('cashier.fields.isValidEnum.false'),
      },
    ];
  });

  const cashierStateOptions = computed(() => {
    return [
      {
        id: true,
        label: i18n.t('cashier.fields.isActiveEnum.true'),
      },
      {
        id: false,
        label: i18n.t('cashier.fields.isActiveEnum.false'),
      },
    ];
  });

  const cashierRoleIsDefaultOptions = computed(() => {
    return [
      {
        id: true,
        label: i18n.t('cashierRole.fields.isDefaultEnum.true'),
      },
      {
        id: false,
        label: i18n.t('cashierRole.fields.isDefaultEnum.false'),
      },
    ];
  });

  const shopStateOptions = computed(() => {
    return [
      {
        id: true,
        label: i18n.t('shop.fields.isActiveEnum.true'),
      },
      {
        id: false,
        label: i18n.t('shop.fields.isActiveEnum.false'),
      },
    ];
  });

  const promotionStateOptions = computed(() => {
    return map(values(PromotionState), (val) => ({
      id: val,
      label: i18n.t(`promotion.fields.stateEnum.${val}`),
    }));
  });

  const promotionIsValidOptions = computed(() => {
    return [
      {
        id: true,
        label: i18n.t('promotion.fields.isActiveEnum.true'),
      },
      {
        id: false,
        label: i18n.t('promotion.fields.isActiveEnum.false'),
      },
    ];
  });

  const voucherEmissionTypeOptions = computed(() => {
    return map(values(VoucherEmissionType), (val) => ({
      id: val,
      label: i18n.t(`voucherEmission.fields.typeEnum.${val}`),
    }));
  });

  const voucherStateOptions = computed(() => {
    return map(values(VoucherState), (val) => ({
      id: val,
      label: i18n.t(`voucher.fields.stateEnum.${val}`),
    }));
  });

  const configurationStateOptions = computed(() => {
    return map(values(ConfigurationState), (val) => ({
      id: val,
      label: i18n.t(`configuration.fields.stateEnum.${val}`),
    }));
  });

  const pointOfSaleVersionUpdateTypeOptions = computed(() => {
    return map(enumStore.enums.value.updateTypes, (type) => ({
      id: type.id.toString(),
      label: i18n.te(`pointOfSaleVersion.fields.typeEnum.${type.name}`) ?
        i18n.t(`pointOfSaleVersion.fields.typeEnum.${type.name}`) :
        type.name,
    }));
  });

  const pointOfSaleVersionUpdateConditionOptions = computed(() => {
    return map(enumStore.enums.value.updateCondition, (type) => ({
      id: type.id.toString(),
      label: i18n.te(`pointOfSaleVersion.fields.conditionEnum.${type.name}`) ?
        i18n.t(`pointOfSaleVersion.fields.conditionEnum.${type.name}`) :
        type.name,
    }));
  });

  const pointOfSaleEverythingSyncedWarningOptions = computed(() => {
    return [
      {
        id: true,
        label: i18n.t('pointOfSale.fields.everythingSyncedWarningEnum.true'),
      },
      {
        id: false,
        label: i18n.t('pointOfSale.fields.everythingSyncedWarningEnum.false'),
      },
    ];
  });

  const pointOfSaleIsOnlineOptions = computed(() => {
    return [
      {
        id: true,
        label: i18n.t('pointOfSale.fields.isOnlineEnum.true'),
      },
      {
        id: false,
        label: i18n.t('pointOfSale.fields.isOnlineEnum.false'),
      },
    ];
  });

  const pointOfSaleIsActiveOptions = computed(() => {
    return [
      {
        id: true,
        label: i18n.t('pointOfSale.fields.isActiveEnum.true'),
      },
      {
        id: false,
        label: i18n.t('pointOfSale.fields.isActiveEnum.false'),
      },
    ];
  });

  const documentTypeOptions = computed(() => {
    return [
      {
        id: DocumentType.closeDay,
        label: i18n.t('general.document.types.closeDay'),
      },
      {
        id: DocumentType.dialogResult,
        label: i18n.t('general.document.types.dialogResult'),
      },
      {
        id: DocumentType.financialCloseDay,
        label: i18n.t('general.document.types.financialCloseDay'),
      },
      {
        id: DocumentType.finDocument,
        label: i18n.t('general.document.types.finDocument'),
      },
      {
        id: DocumentType.inventoryDocument,
        label: i18n.t('general.document.types.inventoryDocument'),
      },
      {
        id: DocumentType.inventorySummaryDocument,
        label: i18n.t('general.document.types.inventorySummaryDocument'),
      },
      {
        id: DocumentType.inventoryFindDiffsDocument,
        label: i18n.t('general.document.types.inventoryFindDiffsDocument'),
      },
      {
        id: DocumentType.lotteryCloseDay,
        label: i18n.t('general.document.types.lotteryCloseDay'),
      },
      {
        id: DocumentType.logisticDocument,
        label: i18n.t('general.document.types.logisticDocument'),
      },
      {
        id: DocumentType.nonFiscalDocument,
        label: i18n.t('general.document.types.nonFiscalDocument'),
      },
      {
        id: DocumentType.openCashDrawer,
        label: i18n.t('general.document.types.openCashDrawer'),
      },
      {
        id: DocumentType.posPayment,
        label: i18n.t('general.document.types.posPayment'),
      },
      {
        id: DocumentType.posPaymentCancel,
        label: i18n.t('general.document.types.posPaymentCancel'),
      },
      {
        id: DocumentType.posPaymentRefund,
        label: i18n.t('general.document.types.posPaymentRefund'),
      },
      {
        id: DocumentType.posPaymentZReport,
        label: i18n.t('general.document.types.posPaymentZReport'),
      },
      {
        id: DocumentType.posStateDocument,
        label: i18n.t('general.document.types.posStateDocument'),
      },
      {
        id: DocumentType.posSalesDocument,
        label: i18n.t('general.document.types.posSalesDocument'),
      },
      {
        id: DocumentType.printDocument,
        label: i18n.t('general.document.types.printDocument'),
      },
      {
        id: DocumentType.safeBag,
        label: i18n.t('general.document.types.safeBag'),
      },
      {
        id: DocumentType.sellDocument,
        label: i18n.t('general.document.types.sellDocument'),
      },
      {
        id: DocumentType.stockReport,
        label: i18n.t('general.document.types.stockReport'),
      },
      {
        id: DocumentType.initPrinter,
        label: i18n.t('general.document.types.initPrinter'),
      },
      {
        id: DocumentType.display,
        label: i18n.t('general.document.types.display'),
      },
      {
        id: DocumentType.goodsReceiptFromDC,
        label: i18n.t('general.document.types.goodsReceiptFromDC'),
      },
      {
        id: DocumentType.goodsReturnToDC,
        label: i18n.t('general.document.types.goodsReturnToDC'),
      },
      {
        id: DocumentType.goodsOrderFromDC,
        label: i18n.t('general.document.types.goodsOrderFromDC'),
      },
      {
        id: DocumentType.goodsReceiptFromPress,
        label: i18n.t('general.document.types.goodsReceiptFromPress'),
      },
      {
        id: DocumentType.goodsReceiptFromExternalSupplier,
        label: i18n.t('general.document.types.goodsReceiptFromExternalSupplier'),
      },
      {
        id: DocumentType.goodsReturnToExternalSupplier,
        label: i18n.t('general.document.types.goodsReturnToExternalSupplier'),
      },
      {
        id: DocumentType.goodsOrderFromExternalSupplier,
        label: i18n.t('general.document.types.goodsOrderFromExternalSupplier'),
      },
      {
        id: DocumentType.goodsReturnToPress,
        label: i18n.t('general.document.types.goodsReturnToPress'),
      },
      {
        id: DocumentType.goodsDepreciationConsumption,
        label: i18n.t('general.document.types.goodsDepreciationConsumption'),
      },
      {
        id: DocumentType.goodsDepreciationDisposal,
        label: i18n.t('general.document.types.goodsDepreciationDisposal'),
      },
      {
        id: DocumentType.goodsReceiptFromPlant,
        label: i18n.t('general.document.types.goodsReceiptFromPlant'),
      },
      {
        id: DocumentType.goodsTransferToPlant,
        label: i18n.t('general.document.types.goodsTransferToPlant'),
      },
      {
        id: DocumentType.stockBalancing,
        label: i18n.t('general.document.types.stockBalancing'),
      },
      {
        id: DocumentType.customerDetail,
        label: i18n.t('general.document.types.customerDetail'),
      },
    ];
  });

  const importStateOptions = computed(() => {
    return map(values(ImportState), (val) => ({
      id: val,
      label: i18n.t(`import.fields.importStateEnum.${val}`),
    }));
  });

  const importTypeOptions = computed(() => {
    return map(values(ImportType), (val) => ({
      id: val,
      label: i18n.t(`import.fields.typeEnum.${val}`),
    }));
  });

  return {
    cashierStateOptions,
    cashierValidityOptions,
    cashierRoleIsDefaultOptions,
    configurationStateOptions,
    promotionIsValidOptions,
    promotionStateOptions,
    shopStateOptions,
    voucherEmissionTypeOptions,
    voucherStateOptions,
    pointOfSaleVersionUpdateTypeOptions,
    pointOfSaleVersionUpdateConditionOptions,
    pointOfSaleEverythingSyncedWarningOptions,
    pointOfSaleIsOnlineOptions,
    pointOfSaleIsActiveOptions,
    documentTypeOptions,
    importStateOptions,
    importTypeOptions,
  };
}
