export default {
  cs: {
    pointOfSale: {
      detail: {
        title: 'POS #{shopCodeAndPosCode}',
        tabs: {
          overview: {
            title: 'Přehled pokladny',
            isOnline: {
              title: 'Stav',
              onlineSince: 'ONLINE',
              offlineSince: 'OFFLINE',
            },
            posVersion: {
              title: 'Verze POS',
            },
            synchronizations: {
              title: 'Synchronizace',
              lastUpdate: 'Poslední aktualizace: {lastUpdate}',
            },
            configurations: {
              title: 'Konfigurace',
              settingsFromAttributes: 'Nastavení přebíráno z atributů',
              version: 'Verze {version}',
            },
            attributes: {
              title: 'Atributy',
              noAttributes: 'Nejsou přiřazeny žádné atributy',
            },
          },
          synchronizations: {
            title: 'Synchronizace',
          },
          versions: {
            title: 'Verze',
          },
          configurations: {
            title: 'Konfigurace',
            actions: {
              create: 'Založit konfiguraci',
            },
          },
          transactions: {
            title: 'Transakce',
          },
        },
        actions: {
          activate: {
            confirm: 'Opravdu chcete pokladnu aktivovat?',
          },
          deactivate: {
            confirm: 'Opravdu chcete pokladnu deaktivovat?',
          },
          assignAttributes: {
            title: 'Upravit POS atributy',
          },
          fourEyesCode: {
            title: 'Kód pro 4 oči',
          },
          generateNewKey: {
            title: 'Vygenerovat nový klíč',
            confirm: 'Opravdu si přejete vygenerovat nový registrační klíč?',
          },
        },
      },
      grid: {
        title: 'Pokladny',
        gridCells: {
          posVersion: {
            version: 'Verze: {version}',
            type: 'Typ: {type}',
            condition: 'Podmínka: {condition}',
            validFrom: 'Aktualizace: {validFrom}',
            description: '{posVersion}, {type} (podmínka: {condition}), {validFrom}',
          },
        },
        actions: {
          create: {
            title: 'Založit',
          },
          attributeAssign: {
            title: 'Přiřadit atributy',
          },
          attributeRemove: {
            title: 'Odebrat atributy',
          },
          versionChange: {
            title: 'Aktualizovat',
          },
          shopDetail: {
            title: 'Detail prodejny',
          },
        },
        filters: {
          search: 'Vyhledat (min. 2 znaky)',
          shop: 'Prodejna',
          attributes: 'Atribut',
          everythingSyncedWarning: 'Stav synchronizace',
          isOnline: 'Online/Offline',
          isActive: 'Stav',
          currentRealVersion: 'Verze',
          posVersionFilter: 'Aktualizace',
        },
        advancedFilters: {
          title: 'Filtrovat pokladny',
          search: 'Vyhledat',
          placeholders: {
            search: 'Zadejte název (min. 2 znaky)',
            shop: 'Vyberte prodejnu',
            attributes: 'Vyberte atribut',
            everythingSyncedWarning: 'Vyberte stav synchronizace',
            isOnline: 'Vyberte stav',
            isActive: 'Vyberte stav',
            currentRealVersion: 'Vyberte verzi',
            posVersionFilter: 'Vyberte aktualizaci',
          },
        },
      },
      form: {
        create: {
          title: 'Nová pokladna',
        },
        update: {
          title: 'Úprava pokladny',
        },
        attributeAssign: {
          title: 'Hromadné přiřazení atributů',
        },
        attributeRemove: {
          title: 'Hromadné odebrání atributů',
        },
        fourEyesCode: {
          title: 'Kód pro 4 oči',
        },
        versionChange: {
          title: 'Hromadná aktualizace verze',
        },
        common: {
          sections: {},
        },
        placeholders: {
          name: 'Zadejte název',
          code: 'Zadejte kód',
          eetIpAddress: 'Zadejte ip adresu EET',
          shop: 'Vyberte prodejnu',
          personalNumber: 'Zadejte číslo pokladního',
        },
      },
      fields: {
        name: 'POS',
        code: 'Kód',
        shop: 'Prodejna',
        shopCode: '#',
        attributes: 'Atributy',
        currentRealVersion: 'Verze POS',
        posVersion: 'Aktualizace',
        everythingSyncedWarning: 'Sync',
        everythingSyncedWarningEnum: {
          true: 'Chybné synchronizace',
          false: 'Kompletní synchronizace',
        },
        isOnline: 'Online',
        isOnlineEnum: {
          true: 'Online',
          false: 'Offline',
        },
        isActive: 'Aktivní',
        isActiveEnum: {
          true: 'Aktivní',
          false: 'Neaktivní',
        },
        singleUseCode: 'Jednorázový klíč',
        currentRealSchemaVersion: 'Verze schématu',
        createdDate: 'Datum vytvoření',
        editedDate: 'Datum editace',
        eetIpAddress: 'IP Adresa EET',
        personalNumber: 'Číslo pokladního',
        edited: 'Upraveno',
        created: 'Vytvořeno',
      },
    },
  },
  sk: {
    pointOfSale: {
      detail: {
        title: 'POS #{shopCodeAndPosCode}',
        tabs: {
          overview: {
            title: 'Prehľad pokladne',
            isOnline: {
              title: 'Stav',
              onlineSince: 'ONLINE',
              offlineSince: 'OFFLINE',
            },
            posVersion: {
              title: 'Verzia POS',
            },
            synchronizations: {
              title: 'Synchronizácie',
              lastUpdate: 'Posledná aktualizácia: {lastUpdate}',
            },
            configurations: {
              title: 'Konfigurácie',
              settingsFromAttributes: 'Nastavenia prevzaté z atribútov',
              version: 'Verzia {version}',
            },
            attributes: {
              title: 'Atribúty',
              noAttributes: 'Nie sú priradené žiadne atribúty',
            },
          },
          synchronizations: {
            title: 'Synchronizácie',
          },
          versions: {
            title: 'Verzie',
          },
          configurations: {
            title: 'Konfigurácie',
            actions: {
              create: 'Vytvoriť konfiguráciu',
            },
          },
          transactions: {
            title: 'Transakcie',
          },
        },
        actions: {
          activate: {
            confirm: 'Naozaj chcete aktivovať pokladňu?',
          },
          deactivate: {
            confirm: 'Naozaj chcete deaktivovať pokladňu?',
          },
          assignAttributes: {
            title: 'Upraviť POS atribúty',
          },
          fourEyesCode: {
            title: 'Kód pre 4 oči',
          },
          generateNewKey: {
            title: 'Generovať nový kľúč',
            confirm: 'Naozaj si prajete vygenerovať nový registračný kľúč?',
          },
        },
      },
      grid: {
        title: 'Pokladne',
        gridCells: {
          posVersion: {
            version: 'Verzia: {version}',
            type: 'Typ: {type}',
            condition: 'Podmienka: {condition}',
            validFrom: 'Aktualizácia: {validFrom}',
            description: '{posVersion}, {type} (podmienka: {condition}), {validFrom}',
          },
        },
        actions: {
          create: {
            title: 'Vytvoriť',
          },
          attributeAssign: {
            title: 'Priradiť atribúty',
          },
          attributeRemove: {
            title: 'Odstrániť atribúty',
          },
          versionChange: {
            title: 'Aktualizovať',
          },
          shopDetail: {
            title: 'Detail predajne',
          },
        },
        filters: {
          search: 'Vyhľadať (min. 2 znaky)',
          shop: 'Predajňa',
          attributes: 'Atribút',
          everythingSyncedWarning: 'Stav synchronizácie',
          isOnline: 'Online/Offline',
          isActive: 'Stav',
          currentRealVersion: 'Verzia',
          posVersionFilter: 'Aktualizácia',
        },
        advancedFilters: {
          title: 'Filtrovať pokladne',
          search: 'Vyhľadať',
          placeholders: {
            search: 'Zadajte názov (min. 2 znaky)',
            shop: 'Vyberte predajňu',
            attributes: 'Vyberte atribút',
            everythingSyncedWarning: 'Vyberte stav synchronizácie',
            isOnline: 'Vyberte stav',
            isActive: 'Vyberte stav',
            currentRealVersion: 'Vyberte verziu',
            posVersionFilter: 'Vyberte aktualizáciu',
          },
        },
      },
      form: {
        create: {
          title: 'Nová pokladňa',
        },
        update: {
          title: 'Upraviť pokladňu',
        },
        attributeAssign: {
          title: 'Hromadné priradenie atribútov',
        },
        attributeRemove: {
          title: 'Hromadné odstránenie atribútov',
        },
        fourEyesCode: {
          title: 'Kód pre 4 oči',
        },
        versionChange: {
          title: 'Hromadná aktualizácia verzie',
        },
        common: {
          sections: {},
        },
        placeholders: {
          name: 'Zadajte názov',
          code: 'Zadajte kód',
          eetIpAddress: 'Zadajte IP adresu EET',
          shop: 'Vyberte predajňu',
          personalNumber: 'Zadajte číslo pokladníka',
        },
      },
      fields: {
        name: 'POS',
        code: 'Kód',
        shop: 'Predajňa',
        shopCode: '#',
        attributes: 'Atribúty',
        currentRealVersion: 'Verzia POS',
        posVersion: 'Aktualizácia',
        everythingSyncedWarning: 'Sync',
        everythingSyncedWarningEnum: {
          true: 'Chybné synchronizácie',
          false: 'Kompletná synchronizácia',
        },
        isOnline: 'Online',
        isOnlineEnum: {
          true: 'Online',
          false: 'Offline',
        },
        isActive: 'Aktívna',
        isActiveEnum: {
          true: 'Aktívna',
          false: 'Neaktívna',
        },
        singleUseCode: 'Jednorázový kľúč',
        currentRealSchemaVersion: 'Verzia schémy',
        createdDate: 'Dátum vytvorenia',
        editedDate: 'Dátum editácie',
        eetIpAddress: 'IP Adresa EET',
        personalNumber: 'Číslo pokladníka',
        edited: 'Upravené',
        created: 'Vytvorené',
      },
    },
  },
  en: {
    pointOfSale: {
      detail: {
        title: 'POS #{shopCodeAndPosCode}',
        tabs: {
          overview: {
            title: 'POS overview',
            isOnline: {
              title: 'Status',
              onlineSince: 'ONLINE',
              offlineSince: 'OFFLINE',
            },
            posVersion: {
              title: 'POS version',
            },
            synchronizations: {
              title: 'Synchronizations',
              lastUpdate: 'Last update: {lastUpdate}',
            },
            configurations: {
              title: 'Configurations',
              settingsFromAttributes: 'Settings from attributes',
              version: 'Version {version}',
            },
            attributes: {
              title: 'Attributes',
              noAttributes: 'No attributes assigned',
            },
          },
          synchronizations: {
            title: 'Synchronizations',
          },
          versions: {
            title: 'Versions',
          },
          configurations: {
            title: 'Configurations',
            actions: {
              create: 'Create configuration',
            },
          },
          transactions: {
            title: 'Transactions',
          },
        },
        actions: {
          activate: {
            confirm: 'Do you really want to activate POS?',
          },
          deactivate: {
            confirm: 'Do you really want to deactivate POS?',
          },
          assignAttributes: {
            title: 'Edit POS attributes',
          },
          fourEyesCode: {
            title: '4 eyes code',
          },
          generateNewKey: {
            title: 'Generate new key',
            confirm: 'Do you really want to generate new registration key?',
          },
        },
      },
      grid: {
        title: 'POS',
        gridCells: {
          posVersion: {
            version: 'Version: {version}',
            type: 'Type: {type}',
            condition: 'Condition: {condition}',
            validFrom: 'Update: {validFrom}',
            description: '{posVersion}, {type} (condition: {condition}), {validFrom}',
          },
        },
        actions: {
          create: {
            title: 'Create',
          },
          attributeAssign: {
            title: 'Assign attributes',
          },
          attributeRemove: {
            title: 'Remove attributes',
          },
          versionChange: {
            title: 'Update',
          },
          shopDetail: {
            title: 'Shop detail',
          },
        },
        filters: {
          search: 'Search (min. 2 characters)',
          shop: 'Shop',
          attributes: 'Attribute',
          everythingSyncedWarning: 'Synchronization status',
          isOnline: 'Online/Offline',
          isActive: 'Status',
          currentRealVersion: 'Version',
          posVersionFilter: 'Update',
        },
        advancedFilters: {
          title: 'Filter POS',
          search: 'Search',
          placeholders: {
            search: 'Enter name (min. 2 characters)',
            shop: 'Select shop',
            attributes: 'Select attribute',
            everythingSyncedWarning: 'Select synchronization status',
            isOnline: 'Select status',
            isActive: 'Select status',
            currentRealVersion: 'Select version',
            posVersionFilter: 'Select update',
          },
        },
      },
      form: {
        create: {
          title: 'New POS',
        },
        update: {
          title: 'Edit POS',
        },
        attributeAssign: {
          title: 'Bulk assign attributes',
        },
        attributeRemove: {
          title: 'Bulk remove attributes',
        },
        fourEyesCode: {
          title: '4 eyes code',
        },
        versionChange: {
          title: 'Bulk update version',
        },
        common: {
          sections: {},
        },
        placeholders: {
          name: 'Enter name',
          code: 'Enter code',
          eetIpAddress: 'Enter EET ip address',
          shop: 'Select shop',
          personalNumber: 'Enter personal number',
        },
      },
      fields: {
        name: 'POS',
        code: 'Code',
        shop: 'Shop',
        shopCode: '#',
        attributes: 'Attributes',
        currentRealVersion: 'POS version',
        posVersion: 'Update',
        everythingSyncedWarning: 'Sync',
        everythingSyncedWarningEnum: {
          true: 'Incorrect synchronizations',
          false: 'Complete synchronizations',
        },
        isOnline: 'Online',
        isOnlineEnum: {
          true: 'Online',
          false: 'Offline',
        },
        isActive: 'Active',
        isActiveEnum: {
          true: 'Active',
          false: 'Inactive',
        },
        singleUseCode: 'Single use code',
        currentRealSchemaVersion: 'Schema version',
        createdDate: 'Created date',
        editedDate: 'Edited date',
        eetIpAddress: 'EET ip address',
        personalNumber: 'Personal number',
        edited: 'Edited',
        created: 'Created',
      },
    },
  },
  de: {
    pointOfSale: {
      detail: {
        title: 'POS #{shopCodeAndPosCode}',
        tabs: {
          overview: {
            title: 'Übersicht über die Kasse',
            isOnline: {
              title: 'Status',
              onlineSince: 'ONLINE',
              offlineSince: 'OFFLINE',
            },
            posVersion: {
              title: 'POS-Version',
            },
            synchronizations: {
              title: 'Synchronisierungen',
              lastUpdate: 'Letztes Update: {lastUpdate}',
            },
            configurations: {
              title: 'Konfigurationen',
              settingsFromAttributes: 'Einstellungen von Attributen übernommen',
              version: 'Version {version}',
            },
            attributes: {
              title: 'Attribute',
              noAttributes: 'Keine Attribute zugewiesen',
            },
          },
          synchronizations: {
            title: 'Synchronisierungen',
          },
          versions: {
            title: 'Versionen',
          },
          configurations: {
            title: 'Konfigurationen',
            actions: {
              create: 'Konfiguration erstellen',
            },
          },
          transactions: {
            title: 'Transaktionen',
          },
        },
        actions: {
          activate: {
            confirm: 'Möchten Sie die Kasse wirklich aktivieren?',
          },
          deactivate: {
            confirm: 'Möchten Sie die Kasse wirklich deaktivieren?',
          },
          assignAttributes: {
            title: 'POS-Attribute bearbeiten',
          },
          fourEyesCode: {
            title: '4-Augen-Code',
          },
          generateNewKey: {
            title: 'Neuen Schlüssel generieren',
            confirm: 'Möchten Sie wirklich einen neuen Registrierungsschlüssel generieren?',
          },
        },
      },
      grid: {
        title: 'Kassen',
        gridCells: {
          posVersion: {
            version: 'Version: {version}',
            type: 'Typ: {type}',
            condition: 'Bedingung: {condition}',
            validFrom: 'Update: {validFrom}',
            description: '{posVersion}, {type} (Bedingung: {condition}), {validFrom}',
          },
        },
        actions: {
          create: {
            title: 'Erstellen',
          },
          attributeAssign: {
            title: 'Attribute zuweisen',
          },
          attributeRemove: {
            title: 'Attribute entfernen',
          },
          versionChange: {
            title: 'Aktualisieren',
          },
          shopDetail: {
            title: 'Filialdetails',
          },
        },
        filters: {
          search: 'Suchen (min. 2 Zeichen)',
          shop: 'Filiale',
          attributes: 'Attribute',
          everythingSyncedWarning: 'Synchronisierungsstatus',
          isOnline: 'Online/Offline',
          isActive: 'Status',
          currentRealVersion: 'Version',
          posVersionFilter: 'Update',
        },
        advancedFilters: {
          title: 'Kassen filtern',
          search: 'Suchen',
          placeholders: {
            search: 'Namen eingeben (min. 2 Zeichen)',
            shop: 'Filiale auswählen',
            attributes: 'Attribute auswählen',
            everythingSyncedWarning: 'Synchronisierungsstatus auswählen',
            isOnline: 'Status auswählen',
            isActive: 'Status auswählen',
            currentRealVersion: 'Version auswählen',
            posVersionFilter: 'Update auswählen',
          },
        },
      },
      form: {
        create: {
          title: 'Neue Kasse',
        },
        update: {
          title: 'Kasse bearbeiten',
        },
        attributeAssign: {
          title: 'Massenzuweisung von Attributen',
        },
        attributeRemove: {
          title: 'Massenentfernung von Attributen',
        },
        fourEyesCode: {
          title: '4-Augen-Code',
        },
        versionChange: {
          title: 'Massenaktualisierung der Version',
        },
        common: {
          sections: {},
        },
        placeholders: {
          name: 'Namen eingeben',
          code: 'Code eingeben',
          eetIpAddress: 'IP-Adresse EET eingeben',
          shop: 'Filiale auswählen',
          personalNumber: 'Kassierernummer eingeben',
        },
      },
      fields: {
        name: 'POS',
        code: 'Code',
        shop: 'Filiale',
        shopCode: '#',
        attributes: 'Attribute',
        currentRealVersion: 'Version POS',
        posVersion: 'Update',
        everythingSyncedWarning: 'Sync',
        everythingSyncedWarningEnum: {
          true: 'Fehlerhafte Synchronisierungen',
          false: 'Komplette Synchronisierung',
        },
        isOnline: 'Online',
        isOnlineEnum: {
          true: 'Online',
          false: 'Offline',
        },
        isActive: 'Aktiv',
        isActiveEnum: {
          true: 'Aktiv',
          false: 'Inaktiv',
        },
        singleUseCode: 'Einmalcode',
        currentRealSchemaVersion: 'Version Schema',
        createdDate: 'Erstellungsdatum',
        editedDate: 'Bearbeitungsdatum',
        eetIpAddress: 'IP-Adresse EET',
        personalNumber: 'Kassierernummer',
        edited: 'Bearbeitet',
        created: 'Erstellt',
      },
    },
  },
};
